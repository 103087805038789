import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Image2 from "../assets/Image2.jpg";
import Image5 from "../assets/Image5.jpg";

const testimonials = [
  {
    name: "Mr. Iftikhar Ahmed",
    role: "Head-Accounts/Financial Reporting",
    content:
      "He holds a B.Sc  Accountancy from the University of Punjab, Pakistan.  He is a member of the Institute of Public Finance Accountants, Pakistan.  He has over 15 years experience in Project Finance, Financial Analysis, Systems implementation and Financial Reporting.",
    avatar: Image2,
  },
  {
    name: "Mr. Jerry O. Omughelli ",
    role: "General Manager - Finance/Admin",
    content:
      "He holds an M.BA in Banking and Finance and M.Sc in Economics both from Enugu State University of Technology (ESUT) Business School, Lagos.  He has over 25 years experience in Finance and Management.",
    avatar: "",
  },

  {
    name: "Capt. Stephen Sobaeh ",
    role: "(Master Marine) - Crew Manager",
    content:
      "He is a seasoned Captain with over 25 years experience in Coastal Tankers of 5,000 to 30,000 tons Deadweight.",
    avatar: "",
  },
  {
    name: "Mr. Malik Asmatullah Khan",
    role: "(Master Mariner) - (DPA)",
    content:
      "Designated Person Ashore (DPA) and Marine Representative of the Company.   He graduated from  Pakistan Marine Academy, Karachi and qualified as a Nautical Cadet.  He has done HND/BTech from Nautical College Fleetwood, Blackpool, United Kingdom.  He also studied in Riversdale  College, Liverpool, United Kingdom and obtained Class 1 Master Mariner  Certificate of Competency.  He has also done BSC and carried out attended several Advance Level courses in .  He has done Lead Auditor , ISM Course from the United Kingdom and ISO.  He is a member of IRCA United Kingdom (International Register of Certified Auditors.  He is also Certified ISO, SON (Standard Organisation of Nigeria) Auditor for Quality Systems.  He also qualified as a Certified Auditor of MLC (Maritime Labour Convention He has sailed as Master on Board Multinational Companies around the world and has wide range of experience.  He also attended Petroleum Tanker  Safety Course and qualified as a Master Mariner. He has worked as a Captain on board various Coastal Tankers of  30,000  to 50,000 tons Deadweight.  He has over 25 years working experience.  ",
    avatar: "",
  },
  {
    name: "Mr. Pervez Anwar",
    role: "Marine Engineer",
    content:
      "He holds Class 1 Marine Engineer Certificate of Competency.   He has a wide range of experience as Chief Engineer.  The has attended numerous marine courses Technical Training Course at I.H. I. Diesel and Boiler works AIOI, japan in construction, Operations, Ship trials and Maintenance of Diesel Engines in 1977.  Construction and maintenance of Deck Machinery for Freedom Vessels at I.H.I. Osaka and Tokyo Yards in 1977, Construction of Hull and Superstructure for Freedom Vessels in I.H.I. Osaka and Tokyo Yards in 1977.  He was also selected as Engineering Instructor for Pakistan Marine Academy, Karachi by the Federal Public Service Commission in Grade 19 (Associate Professor) a position he served for 10 years.  In 1988, he  attended a Computers on Simulation in Marine Engineering course at College of Maritime Studies, Warshash, Southampton, United Kingdom.  He also attended Engine Plant Simulator Course for Chief Engineers at the College of Maritime Studies, Warsash, Southampton, United Kindgom",
    avatar: "",
  },
];

function TestimonialCard(props) {
  const { name, role, content, avatar } = props;

  return (
    <Container maxW={"7xl"} p={{ base: 4, md: 0 }}>
      <Flex
        boxShadow={"lg"}
        maxW={"640px"}
        direction={{ base: "column-reverse", md: "row" }}
        width={"full"}
        rounded={"xl"}
        p={10}
        justifyContent={"space-between"}
        position={"relative"}
        bg={useColorModeValue("white", "gray.800")}>
        <Flex
          direction={"column"}
          textAlign={"left"}
          justifyContent={"space-between"}>
          <chakra.p
            fontFamily={"Work Sans"}
            fontWeight={"bold"}
            pb={4}
            fontSize={14}>
            {name}
            <chakra.span
              fontFamily={"Inter"}
              fontWeight={"medium"}
              color={"gray.500"}>
              {" "}
              - {role}
            </chakra.span>
          </chakra.p>
          <chakra.p
            fontFamily={"Inter"}
            fontWeight={"medium"}
            fontSize={"15px"}>
            {content}
          </chakra.p>
        </Flex>
        {/* Render Avatar with fallback to default image */}
        <Avatar
          src={avatar || Avatar} // If no avatar, use the DefaultAvatar
          height={"80px"}
          width={"80px"}
          alignSelf={"center"}
          m={{ base: "0 0 35px 0", md: "0 0 0 50px" }}
        />
      </Flex>
    </Container>
  );
}

export default function GridBlurredBackdrop() {
  return (
    <Flex
      px={{ base: "1em" }}
      textAlign={"center"}
      pt={10}
      justifyContent={"center"}
      direction={"column"}
      width={"full"}
      overflow={"hidden"}>
      <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
        <chakra.h3
          fontFamily={"Work Sans"}
          fontWeight={"bold"}
          fontSize={20}
          textTransform={"uppercase"}
          color={useColorModeValue("gray.700", "gray.50")}
          pt={8}>
          We are Excellence in service delivery.
        </chakra.h3>
        <chakra.h1
          pb={5}
          fontSize={48}
          fontFamily={"Work Sans"}
          fontWeight={"bold"}
          color={useColorModeValue("gray.700", "gray.50")}>
          You're in good company
        </chakra.h1>
        <chakra.h2
          margin={"auto"}
          fontFamily={"Inter"}
          fontWeight={"medium"}
          color={useColorModeValue("gray.500", "gray.400")}>
          Meet our amazing team, a group of dedicated individuals who bring
          unique skills and expertise to the table, working together to achieve
          our common goals and deliver exceptional results.
        </chakra.h2>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={"20"}
        mt={16}
        mb={16}
        mx={"auto"}>
        {testimonials.map((cardInfo, index) => (
          <TestimonialCard key={index} {...cardInfo} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}
