import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Image2 from "../assets/Image2.jpg";
import Image4 from "../assets/Image4.jpg";
import Image3 from "../assets/Image3.jpg";
import Image5 from "../assets/Image5.jpg";
import Image1 from "../assets/Image1.jpeg";
import Image6 from "../assets/Image6.jpg";

const testimonials = [
  {
    name: "Hon. MD Abubakar IGP Retired",
    role: "Chairman",
    image: Image6,
  },
  {
    name: "Mr. Tousif Mohammed Paracha",
    role: "Directors",
    image: Image5,
  },
  {
    name: "Prince A. A.  Ademiluyi",
    role: "Director",
    image: Image4,
  },
  {
    name: "Mr. Luqman Mamuda",
    role: "Member Board of Directors",
    image: Image1,
  },
  {
    name: "Alh. Mohammed Rehman",
    role: "Member Board of Directors",
    image: Image3,
  },
  {
    name: "Mr. Jerry O. Omughelli ",
    role: "General Manager - Finance/Admin",
    image: "",
  },

  {
    name: "Dr. G. Akisanya",
    role: "Executive Director",
    image: "",
  },
  {
    name: "Barrister G.T. J. Ademola",
    role: "Director",
    image: "",
  },
  {
    name: "Mr. Ibrahim Bayero",
    role: "Member Board of Directors",
    image: "",
  },
  {
    name: "Mr. Iftikhar Ahmed",
    role: "Head-Accounts/Financial Reporting",
    image: Image2,
  },
  {
    name: "Capt. Stephen Sobaeh ",
    role: "(Master Marine) - Crew Manager",
    image: "",
  },
  {
    name: "Mr. Malik Asmatullah Khan",
    role: "(Master Mariner) - (DPA)",
    image: "",
  },
  {
    name: "Mr. Pervez Anwar",
    role: "Marine Engineer",
    image: "",
  },
];

function TestimonialCard(props) {
  const { name, role, content, image } = props;

  return (
    <Container maxW={"7xl"} p={{ base: 4, md: 0 }}>
      <Flex
        boxShadow={"lg"}
        maxW={"640px"}
        direction={{ base: "column-reverse", md: "row" }}
        width={"full"}
        sx={{ height: "12em" }}
        rounded={"xl"}
        mt={2}
        p={10}
        justifyContent={"space-between"}
        position={"relative"}
        bg={useColorModeValue("white", "gray.800")}>
        <Flex
          direction={"column"}
          textAlign={"left"}
          justifyContent={"space-between"}>
          <chakra.p
            fontFamily={"Work Sans"}
            fontWeight={"bold"}
            pb={4}
            fontSize={14}>
            {name}
            <chakra.span
              fontFamily={"Inter"}
              fontWeight={"medium"}
              color={"gray.500"}>
              {" "}
              - {role}
            </chakra.span>
            <chakra.p
              fontFamily={"Inter"}
              fontWeight={"medium"}
              fontSize={"15px"}
              paddingTop={2}>
              {content}
            </chakra.p>
          </chakra.p>
        </Flex>
        {/* Render Avatar with fallback to a default image */}
        <Avatar
          src={image || Avatar} // If no image, use DefaultAvatar
          height={"80px"}
          width={"80px"}
          alignSelf={"center"}
          m={{ base: "0 0 35px 0", md: "0 0 0 50px" }}
        />
      </Flex>
    </Container>
  );
}

export default function GridBlurredBackdrop() {
  return (
    <Flex
      px={{ base: "1em" }}
      textAlign={"center"}
      pt={10}
      justifyContent={"center"}
      direction={"column"}
      width={"full"}
      overflow={"hidden"}>
      <Box width={{ base: "full", sm: "lg", lg: "xl" }} margin={"auto"}>
        <chakra.h3
          fontFamily={"Work Sans"}
          fontWeight={"bold"}
          fontSize={20}
          textTransform={"uppercase"}
          color={useColorModeValue("gray.700", "gray.50")}
          pt={8}>
          We are Excellence in service delivery.
        </chakra.h3>
        <chakra.h1
          pb={5}
          fontSize={48}
          fontFamily={"Work Sans"}
          fontWeight={"bold"}
          color={useColorModeValue("gray.700", "gray.50")}>
          You're in good company
        </chakra.h1>
        <chakra.h2
          margin={"auto"}
          fontFamily={"Inter"}
          fontWeight={"medium"}
          color={useColorModeValue("gray.500", "gray.400")}>
          Meet our amazing team, a group of dedicated individuals who bring
          unique skills and expertise to the table, working together to achieve
          our common goals and deliver exceptional results.
        </chakra.h2>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={"20"}
        mt={16}
        mb={16}
        mx={"auto"}>
        {testimonials.map((cardInfo, index) => (
          <TestimonialCard key={index} {...cardInfo} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}
